.News{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 1040px;
}

.News h1{
    text-align: center ;
}

