*{
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  /* background-color: #f8f9fa; */
}

a{
  text-decoration: none;
  color: black
}

.classicalFont{
    font-family: 'Playfair Display SC', serif;
    letter-spacing: 0.1em;
    font-weight: 600;
}