.ChoirDirection{
    max-width: 1140px;
    margin: auto;
}

.ChoirDirection__headline{
    text-align: center;
    margin-top: 3rem;
}

.ChoirDirection__image{
    border-radius: 10px;
    box-shadow: 3px 3px 15px rgba(73, 58, 44, .8);
    margin: 3rem auto;
    display: block;
}

.ChoirDirection__detail{
    padding: 3rem;
}