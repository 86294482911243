.Banner{
    padding-top: 8rem;
    width: 100%;
    height: 350px;
    background: linear-gradient(0deg, #493a2ccc, rgba(102, 86, 72, 0.8) 80%, rgba(139, 125, 111, 0.8)), url('../../assets/media/img/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Banner_textContainer{
    text-align: center;
    font-family: 'Playfair Display SC', serif;
    color: white;
    font-size: 3.5rem;
    letter-spacing: .1em;
    line-height: 0;
    font-weight: 600;
}

.Banner__strechedHeadline{
    letter-spacing: 0.53em;
}

.Banner__callToAction{
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 8px 12px;
    transition: .3s ease-out;
}

.Banner__callToAction:hover{
    color: black;
    background-color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 8px 12px;
}

.Banner__choirDirectorImage{
    position: relative;
    width: 150px;
    right: 5%;
    bottom: 1rem;
    align-self: flex-end;
    box-shadow: 3px 3px 15px rgba(73, 58, 44, 0.8);
}

.clearfix:after {
    display: table;
    content: '';
    clear: both;
}

@media all and (max-width: 600px){
    .Banner{
        padding: 0;

    }
    .Banner__choirDirectorImage{
        display: none;
    }

    .Banner_textContainer{
        font-size: 2em;
    }
}