.NewsArticle{
    border: 1px solid rgba(0, 0, 0, .7);
    padding: 16px;
    border-radius: 5px;
    background: #f8f9fa;
    box-shadow: 0 1px 5px #00000080;
    display: flex;
}

.NewsArticle{
    margin: 2rem;
}

.NewsArticle__titleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid black;
}

.NewsArticle h3{    
    display: inline-block;
    padding: 10px;
    align-self: center;
    min-width: 300px;
    
}

.NewsArticle__text{
    padding-left: 16px;
    align-self: center;
    /* border-left: 2px solid black; */
}

.NewsArticle__imageContainer{
    width: 100%;
    /* border-left: 2px solid black; */
    padding: 16px;
    overflow-y: auto;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NewsArticle__imageContainer img{
    max-width: 250px;
    margin: 12px 0 12px 0;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(73, 58, 44, 0.8);
}

.NewsArticle span{
    font-style: italic;
    font-size: 11px;
    align-self: flex-end;
}

@media (max-width: 960px){
    .NewsArticle{
        flex-direction: column;
    }

    .NewsArticle__titleContainer{
        border: none
    }

    .NewsArticle__imageContainer{
        margin: auto;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .NewsArticle__imageContainer img{
        width: 100%;
        max-width: 960px;
        margin: 16px;
    }

    .NewsArticle h3{
        border-bottom: 2px solid black;
        text-align: center;
    }
}