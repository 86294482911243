.AboutUsContent{
    max-width: 1140px;
    margin: auto;
    color: white;
    line-height: 1.5;
    padding: 24px;
    width: 100%;
}

  .AboutUsContent h1{
    font-family: 'Playfair Display SC', serif;
    letter-spacing: 0.1em;
    font-weight: 600;
    color: white;
    margin-bottom: 3rem;
    font-size: 2.5rem;
  }


  .AboutUsImage{
      margin: auto;
      border-radius: 10px;
      box-shadow: 3px 3px 15px rgba(73, 58, 44, 1);
      width: 100%;
  }