.Footer{
    margin-top: 2rem;
}

.Footer__cantica{
    display: flex;
    justify-content: space-evenly;

    padding: 1rem;
    /* background-color: rgba(102, 86, 72, 0.8); */
    font-size: 1.1rem;
    box-shadow: 0 -1px 5px #00000080;
}

.Footer__tg{
    text-align: center;
    padding-bottom: 5px;
}

.Footer__tg a{
    padding: 16px;
    text-decoration: underline;
    color: blue;
}

@media screen and (max-width:630px){
    .Footer__cantica{
        flex-direction: column;
    }

    .Footer__cantica div{
        margin-bottom: 1rem;
    }
}