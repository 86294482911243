.Media{
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
}

.Media__slideshow{
    width: 100%;
    max-width: 1100px;
    margin: auto;
    text-align: center;
}

.Media__audio{
    padding: 1rem;
    background-color:  rgba(102, 86, 72, 0.8);
    width: 100%;
    color: white;
    margin-top: 1rem;
    text-align: center;
}

.Media__audio li{
    list-style-type: none;
}

.Media__video{
   margin:auto;
    text-align: center;
}