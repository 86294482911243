.Navigation{
    padding: 0 1rem;
    background-color: #f8f9fa;
    overflow: hidden;
    box-shadow: 0 1px 5px #00000080;
}

.Navigation__toggle{
    order: 1;
    font-size: 2.5rem;
    cursor: pointer;
}

.Navigation__logo{
    font-family: 'Playfair Display SC', serif;
    font-size: 1.5rem;
    letter-spacing: .1em;
    font-weight: 600;
    
}

.Navigation__listRoot{
    list-style: none;
    display: flex;
    margin: 1rem .25rem;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.Navigation__listItem{
    width: 100%;
    text-align: center;
    order: 2;
    padding: 5px;
    display: none;
}

.Navigation__listItem a{
    font-family: 'Playfair Display SC', serif;
    font-weight: 600;
    color: #00000080;
    padding: .5rem .5rem;
    letter-spacing: .1em;
    font-size: 1.5em;
}

.Navigation__listItem a:hover{
    color: black;    
}

.Navigation__listItem.active{
    display: block;
}

.Navigation__icon{
    display: none;
}


/* Desktop view */
@media all and (min-width: 1064px){
    .Navigation__listItem{
        display: block;
        width: auto;
    }

    .Navigation__toggle{
        display: none;
    }

    .Navigation__logo{
        order: 0;
        flex-grow: 1;
    }

    .Navigation__icon{
        order: 1;
    }
    .Navigation__listItem{
        padding: .1rem 1rem;
    }
    .Navigation__listItem a{
        font-size: 1rem;    
    }
}
