.CoronaNote{
    display: flex;
    padding: 1rem;
    background-color: rgba(255, 123, 0, 0.63);
    align-items: center;
    justify-content: center;
}

.CoronaNote img{
    height: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.CoronaNote a{
    color: blue;
    text-decoration: underline;
}