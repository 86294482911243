.VideoSection{
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    width: 80%;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.VideoSection video{
    border-radius: 10px;
    box-shadow: 3px 3px 15px rgba(73, 58, 44, 1);
    background-position: bottom;
}


.VideoSection__overlayTextInnerContainer{
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.52);
    padding: 16px 40px;
    border-radius: 10px;
}

.VideoSection__overlayTextInnerContainer h1{
    font-family: 'Playfair Display SC', serif;
    font-size: 3rem;
}

.VideoSection__overlayTextInnerContainer p{
    font-size: 1.2rem;
}

.VideoSection__muteButton{
    margin: 16px;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 8px 16px;
    transition: .3s ease-out;
    cursor: pointer;
}

.VideoSection__muteButton:hover{
    background-color: white;
    color:black;
}

@media all and (max-width: 600px){
    .VideoSection{display: none;}
}