.ChronicalChoirDirection{
    /* background: linear-gradient(0deg, #fff, rgba(102, 86, 72, 0.8) 20%); */
    background: #665648cc;
    padding: 3rem;
    color: white;
    margin: 3rem 0;
}

.ChronicalChoirDirection.wh{
    /* background: linear-gradient(0deg, #fff, rgba(102, 86, 72, 0.8) 20%); */
    background:#fff;
    color: black;
}

.ChronicalChoirDirection__container{
    max-width: 1040px;
    margin: auto;
    display: flex;
    border-left: 10px solid white;
    padding-bottom: 3rem;
    justify-content: space-between;
}

.ChronicalChoirDirection__container.wh{
    border-right: 10px solid #665648cc;
    border-left: none;
}

.ChronicalChoirDirection__shorthand{
    margin: 0 16px;
    display: block;
    width: 30%;
}

.ChronicalChoirDirection__shorthand.wh{
    text-align: right;
    order: 1;
}

.ChronicalChoirDirection__shorthand h3{
    margin-top: 0;
    font-family: 'Playfair Display SC', serif;
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size: 1.5rem;
}

.ChronicalChoirDirection__detail{
    width: 70%;
}

.ChronicalChoirDirection__detail>p{ 
    margin-top: 0;
}

@media all and (max-width: 769px){
    .ChronicalChoirDirection{
        padding: 16px;
    }

    .ChronicalChoirDirection__container{
        flex-direction: column;
        padding: 16px;
    }

    .ChronicalChoirDirection__shorthand{
        width: 100%;
        margin-bottom: 2rem;
    }

    .ChronicalChoirDirection__shorthand.wh{
        text-align: left;
        order: 0;
    }

    .ChronicalChoirDirection__detail{
        width: 100%;
    }

    .ChronicalChoirDirection__detail>p{
        margin: 16px;
    }
}