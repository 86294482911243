.ChronicalSection{
    padding: 2rem 3rem;
    max-width: 1140px;
    margin: auto;
}

.ChronicalSection__title{
    font-family: 'Playfair Display SC', serif;
    letter-spacing: .1em;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.ChronicalSection ul li{
    line-height: 1.5;
    padding: 5px 5px;
}