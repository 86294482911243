.Practice{
    max-width: 950px;
    margin: auto;
    text-align: center;
}

.Practice__title{
    text-align: center;
    margin-bottom: 2rem;
}

.Practice__maps{
    border-radius: 10px;
    width: 100%;
    margin: 2rem auto;
    box-shadow: 3px 3px 15px rgba(73, 58, 44, 0.8);
}