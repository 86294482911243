.CardDeck{
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    max-width: 1024px;
    padding: 0;
    margin-bottom: 5rem;
  }

.Home__aboutUs{
    background-color: rgba(102, 86, 72, 0.8);
    text-align: center;
    padding-top: 2rem;
}

.Home__videoSection{
    text-align: center;
    padding-top: 2rem;
}


@media(max-width: 1148px){
    .CardDeck{ 
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

  @media(max-width: 780px){
      .CardDeck{
          margin: auto;
          justify-content: center;
          flex-direction: column;
          width: 90%;
      }
  }