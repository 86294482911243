.Card{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto;
    box-shadow: 3px 3px 15px rgba(73, 58, 44, 0.8);
    margin: 16px;
    max-width: 300px;
    border-radius: 5px;
}

.Card__image{
    width: 300px;
    height: 200px;
    border-radius: 5px 5px 0 0;
}

.Card__title{
    text-align: center;
    margin-bottom: .75rem;
    margin-top: 0;
    font-size: 1.25rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.Card__body{
    flex: 1 1 auto;
    padding: 1.25rem;
    overflow: hidden;
}


.Card__small{
    height: 350px;
    position: relative;
}

.Card__small:after {
    content:"";
    position:absolute;
    top:70%;
    left:0;
    height:30%;
    width:100%;
    background: linear-gradient(rgba(0,0,0,0), #FFF 95%);
}

.Card__more span{
    padding: 1.25rem;
    color: #003aad;
    float: right;
    cursor: pointer;
    font-size: .8rem;
}

@media(max-width: 780px){
    .Card{
        max-width: 300px;
    }
}