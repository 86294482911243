.ExampleListItem__white{
    padding: 1rem;
    border-radius: 5px;
    margin: 1rem 0;
}

.ExampleListItem__brown{
    background-color: rgba(102, 86, 72, 0.8);
    color: white;
    padding: 1rem;
    border-radius: 5px;
    text-align: right;
    width: 100%;
}